import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const NavSocial = ({ cssClass }) => {
  const intl = useIntl();
  return (
    <ul className={`social ${cssClass ? cssClass : ''}`}>
      <li>
        <a
          href="https://www.instagram.com/publicisconseil/"
          target="_blank"
          className="has-icon"
          rel="noreferrer"
          title={intl.formatMessage({ id: 'social.instagram' })}
        >
          <FaInstagram className="icon" />
        </a>
      </li>
      <li>
        <a
          href="https://fr.linkedin.com/company/publicis-conseil"
          target="_blank"
          className="has-icon"
          rel="noreferrer"
          title={intl.formatMessage({ id: 'social.linkedin' })}
        >
          <FaLinkedinIn className="icon" />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/PublicisConseil"
          target="_blank"
          className="has-icon"
          rel="noreferrer"
          title={intl.formatMessage({ id: 'social.twitter' })}
        >
          <FaTwitter className="icon" />
        </a>
      </li>
    </ul>
  );
};

export default NavSocial;
