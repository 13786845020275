import React from 'react';
import {
  IntlContextConsumer,
  changeLocale,
  injectIntl,
} from 'gatsby-plugin-intl';

const languageName = {
  en: {
    short: 'EN',
    long: 'Switch language to English',
  },
  fr: {
    short: 'FR',
    long: 'Changer la langue en Français',
  },
};

const Language = ({ languageTo }) => {
  const handleKeyDown = (e, language, to) => {
    // check keys if you want
    if (e.keyCode == 13) {
      changeLocale(language, to);
      setTimeout(() => {
        window.location.reload(true);
      }, 500);
    }
  };

  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages?.map(language => {
            const to = languageTo[language];
            return (
              <a
                key={language}
                onClick={() => {
                  changeLocale(language, to);
                  setTimeout(() => {
                    window.location.reload(true);
                  }, 500);
                }}
                onKeyDown={e => handleKeyDown(e, language, to)}
                className={`navbar-item ${
                  currentLocale === language ? 'is-active' : ''
                }`}
                title={languageName[language].long}
                aria-label={languageName[language].long}
                lang={languageName[language].short}
                tabIndex="0"
              >
                {languageName[language].short}
              </a>
            );
          })
        }
      </IntlContextConsumer>
    </div>
  );
};

Language.defaultProps = {
  languageTo: [],
};

export default injectIntl(Language);
