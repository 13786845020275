import React from 'react';
import { injectIntl, Link } from 'gatsby-plugin-intl';

import { CookieSetting } from '../../../../plugins/epsilon-gatsby-plugin-onetrust';

const NavMl = ({ intl, navLinks, cssClass }) => {
  return (
    <ul className={` ${cssClass ? cssClass : ''}`}>
      {navLinks[0]?.colLegaleMention?.map(({ seo }, i) => {
        return (
          <li key={i}>
            <Link to={`/${seo.slug}`}>{seo.title}</Link>
          </li>
        );
      })}
      <li>
        <CookieSetting
          label={intl.formatMessage({ id: 'footer.manage_cookie' })}
        />
      </li>
    </ul>
  );
};

export default injectIntl(NavMl);
