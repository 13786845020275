import React from 'react';
import { Link, FormattedMessage, injectIntl } from 'gatsby-plugin-intl';

function NavPages({ cssClass, activeMenuLink, intl }) {
  return (
    <ul className={` ${cssClass ? cssClass : ''}`}>
      <li>
        <Link
          to="/work"
          className={activeMenuLink === 'work' ? 'is-active' : ''}
        >
          Work
        </Link>
      </li>
      <li>
        <Link
          to="/news"
          className={activeMenuLink === 'news' ? 'is-active' : ''}
        >
          News
        </Link>
      </li>
      <li>
        <Link
          to="/rse"
          className={activeMenuLink === 'rse' ? 'is-active' : ''}
          arial-label={intl.formatMessage({
            id: 'navigation.def_rse',
          })}
        >
          <FormattedMessage id="menu_rse" />
        </Link>
      </li>
      <li>
        <Link
          to="/about-us"
          className={activeMenuLink === 'aboutus' ? 'is-active' : ''}
        >
          About us
        </Link>
      </li>
      <li>
        <Link
          to="/joinus"
          className={activeMenuLink === 'joinus' ? 'is-active' : ''}
        >
          Join us
        </Link>
      </li>
    </ul>
  );
}

export default injectIntl(NavPages);
