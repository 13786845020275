import React, { useState, useEffect, useRef } from 'react';
import { injectIntl, useIntl } from 'gatsby-plugin-intl';

import NavPages from './navigation/menu/navPages';
import NavMl from './navigation/menu/navMl';

import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';

const Footer = ({ navLinks }) => {
  const intl = useIntl();

  const refFooter = useRef();

  const [closeFooter, setCloseFooter] = useState(true);
  const [isFixed, setIsFixed] = useState(true);

  const [clientHeight, setClientHeight] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);

  const scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;

    if (scrollPx >= offsetHeight - clientHeight) {
      setIsFixed(false);
    } else {
      setIsFixed(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollProgress);

    return () => {
      window.removeEventListener('scroll', scrollProgress);
    };
  });

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setClientHeight(document.documentElement.clientHeight);
      setOffsetHeight(document.documentElement.offsetHeight);
    }
  }, [refFooter]);

  return (
    <>
      <footer
        role="contentinfo"
        ref={refFooter}
        className={`footer ${closeFooter ? 'is-closed' : ''} ${
          isFixed ? 'is-fixed' : ''
        }`}
      >
        {isFixed && (
          <span
            className="has-icon footer__button"
            onClick={() => setCloseFooter(!closeFooter)}
          >
            {closeFooter && (
              <>
                <IoMdArrowDropup
                  className="icon"
                  title={intl.formatMessage({ id: 'footer.footer_links_open' })}
                  aria-hidden={true}
                />
                {intl.formatMessage({ id: 'footer.footer_links_open' })}
              </>
            )}
            {!closeFooter && (
              <>
                <IoMdArrowDropdown
                  className="icon"
                  title={intl.formatMessage({
                    id: 'footer.footer_links_close',
                  })}
                  aria-hidden={true}
                />
                {intl.formatMessage({ id: 'footer.footer_links_close' })}
              </>
            )}
          </span>
        )}
        <nav
          className="navbar is-uppercase has-text-white is-text-indication"
          role="navigation"
          aria-label="footer navigation"
        >
          <NavPages cssClass="first-col menu-footer-pages" />

          <NavMl navLinks={navLinks} cssClass="menu-footer-ml" />
        </nav>
      </footer>
    </>
  );
};

export default injectIntl(Footer);
