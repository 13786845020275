/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Logo from '../images/publicis-conseil-share.png';

function SEO({
  description,
  lang,
  meta,
  title,
  imageLink,
  pageLink,
  titleOnglet,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const metaTitle = title ? title : defaultTitle;
  const metaImage = imageLink || Logo;
  const metaUrl = pageLink
    ? site.siteMetadata.siteUrl + pageLink
    : site.siteMetadata.siteUrl;

  return (
    <Helmet
      title={metaTitle}
      titleTemplate={
        titleOnglet ? `${titleOnglet} | ${defaultTitle}` : defaultTitle
      }
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: metaUrl,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:url',
          content: metaUrl,
        },
        {
          property: 'twitter:image',
          content: metaImage,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'fr',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
