import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { StaticImage } from 'gatsby-plugin-image';

import Language from './language';
import NavPages from './navigation/menu/navPages';
import NavContact from './navigation/menu/navContact';
import NavSocial from './navigation/menu/navSocial';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.expandMenuRef = React.createRef();

    this.state = {
      showMenu: false,
      lastScroll: 0,
      scrollUp: 'scroll-up',
      scrollDown: 'scroll-down',
      width: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.showMenuScroll);
    document.addEventListener('click', this.handleClickOutsideMenu);
    window.addEventListener('resize', this.resizeWindow);
    this.resizeWindow();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.showMenuScroll);
    document.removeEventListener('click', this.handleClickOutsideMenu);
    window.addEventListener('resize', this.resizeWindow);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openMenu !== this.props.openMenu) {
      this.updateShowMenu(null, this.props.openMenu);
    }
  }

  // Get width window
  resizeWindow = () => {
    this.setState({ width: window.innerWidth });
  };

  // Close menu clic anywhere
  handleClickOutsideMenu = event => {
    var linkMenu = document.querySelector('.link-menu');
    if (
      this.state.showMenu &&
      !this.props.openMenu &&
      this.expandMenuRef &&
      !this.expandMenuRef.current.contains(event.target) &&
      event.target != linkMenu
    ) {
      this.updateShowMenu(event, false);
    }
  };

  showMenuScroll = () => {
    const body = document.body;
    const { scrollUp, scrollDown } = this.state;

    var lastScroll = this.state.lastScroll;

    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      // down
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      // up
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);
    }
    this.setState({ lastScroll: currentScroll });
  };

  // Toggle Menu
  updateShowMenu = (event, viewMenu) => {
    if (event) {
      event.preventDefault();
    }

    var eltPage = document.querySelector('main');
    if (!viewMenu) {
      eltPage.classList.remove('with-expand-menu');
    } else {
      eltPage.classList.add('with-expand-menu');
    }
    this.setState({ showMenu: viewMenu });
  };

  handleKeyDown = e => {
    // check keys if you want
    if (e.keyCode == 13) {
      this.updateShowMenu(event, false);
      window.location = e.target.hash;
    }
  };

  render() {
    const { headerCssClass, languageTo, activeMenuLink, navLinks } = this.props;
    let { showMenu, width } = this.state;

    return (
      <>
        <Helmet>
          <html className={showMenu ? 'is-clipped' : ''} />
          {/** Matomo **/}
          <script type="text/plain" class="optanon-category-C0007">
            {`let _paq = window._paq = window._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
              let u="${process.env.GATSBY_MATOMO_TAG_URL}";
              _paq.push(['setTrackerUrl', u+'matomo.php']);
              _paq.push(['setSiteId', '${process.env.GATSBY_MATOMO_TAG_SITE_ID}']);
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();`}
          </script>
          {/** End Matomo Code **/}
        </Helmet>
        <header
          id="banner"
          role="banner"
          className={`header ${headerCssClass ? headerCssClass : ''}`}
        >
          <nav className="navbar">
            <div className="navbar-brand">
              <Link className="navbar-item" to="/">
                <StaticImage
                  src="../images/logo-logo-conseil.svg"
                  placeholder="blurred"
                  width={200}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Logo Publicis Conseil"
                />
              </Link>
            </div>

            <div
              className="navbar-menu is-text-indication is-uppercase"
              role="navigation"
              aria-label="main navigation"
            >
              <div className="navbar-end">
                <span className="languages columns is-hidden-mobile mx-4 my-0">
                  <Language languageTo={languageTo} />
                </span>

                <button
                  id="main-menu"
                  className={`navbar-item link-menu ${showMenu ? 'close' : ''}`}
                  onClick={e => this.updateShowMenu(e, !showMenu)}
                >
                  {width <= 760 ? (
                    <>
                      Menu
                      <span className="icon-menu"></span>
                    </>
                  ) : !showMenu ? (
                    <>
                      Menu
                      <span className="icon-menu"></span>
                    </>
                  ) : (
                    <>
                      <FormattedMessage id="close_btn" />
                      <span className="icon-close-circle"></span>
                    </>
                  )}
                </button>

                <div
                  ref={this.expandMenuRef}
                  className={`expand-menu ${showMenu ? 'open' : ''}`}
                  aria-expanded={showMenu}
                  aria-haspopup="true"
                  hidden={!showMenu}
                >
                  <div className="p-5 pt-0">
                    {width <= 760 ? (
                      <>
                        <a
                          href="#"
                          onClick={e => this.updateShowMenu(e, !showMenu)}
                          className="close"
                        >
                          <FormattedMessage id="close_btn" />{' '}
                          <span className="icon-close-circle"></span>
                        </a>

                        <span className="languages columns my-4">
                          <Language languageTo={languageTo} />
                        </span>
                      </>
                    ) : (
                      ''
                    )}

                    <a
                      href="#maincontent"
                      className="skip-links"
                      onKeyDown={e => this.handleKeyDown(e)}
                    >
                      <FormattedMessage id="skip_menu" />
                    </a>

                    <NavPages
                      cssClass="main-menu"
                      activeMenuLink={activeMenuLink}
                    />

                    <div className="footer-menu columns is-text-indication">
                      <NavSocial cssClass="column" />
                    </div>
                  </div>
                </div>

                {showMenu && (
                  <button
                    className={`navbar-item link-menu close menu-tab`}
                    onClick={e => this.updateShowMenu(e, !showMenu)}
                  >
                    <FormattedMessage id="close_btn" />
                    <span className="icon-close-circle"></span>
                  </button>
                )}
              </div>
            </div>
          </nav>
        </header>
      </>
    );
  }
}
