/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';

import { CookieList } from '../../plugins/epsilon-gatsby-plugin-onetrust';

import Header from './header';
import Footer from './footer';

const Layout = ({
  children,
  intl,
  languageTo,
  headerCssClass,
  mainCssClass,
  activeMenuLink,
  isPagePolicy = false,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleAndMLQuery {
      site {
        siteMetadata {
          title
        }
      }

      allContentfulMenuMl {
        nodes {
          node_locale
          colLegaleMention {
            seo {
              slug
              title
            }
          }
          colContact {
            seo {
              slug
              title
            }
          }
        }
      }

      allFile(
        filter: { sourceInstanceName: { eq: "css" }, name: { eq: "print" } }
      ) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  const { locale } = intl;
  const navLinks = data.allContentfulMenuMl.nodes.filter(
    seo => seo.node_locale === locale
  );

  const printCSS = data.allFile.nodes[0].publicURL;

  let lang = locale;
  if (isPagePolicy) {
    lang = locale == 'fr' ? 'fr-fr' : 'en-us';
  }

  const [openMenu, setOpenMenu] = useState(false);

  const handleKeyDown = e => {
    // check keys if you want
    if (e.keyCode == 13) {
      setOpenMenu(true);
    }
  };

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
      >
        <link rel="stylesheet" type="text/css" href={printCSS} media="print" />

        <script
          type="text/javascript"
          src={`https://cdn.cookielaw.org/consent/${process.env.GATSBY_ONETRUST_DOMAIN_SCRIPT}/OtAutoBlock.js`}
        />
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-document-language="true"
          type="text/javascript"
          data-domain-script={process.env.GATSBY_ONETRUST_DOMAIN_SCRIPT}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: 'function OptanonWrapper() {}',
          }}
        />
      </Helmet>
      <nav>
        <ul>
          <li>
            <a href="#maincontent" className="skip-links">
              <FormattedMessage id="skip_links" />
            </a>
          </li>
          <li>
            <a
              href="#main-menu"
              className="skip-links"
              onKeyDown={e => handleKeyDown(e)}
              onClick={() => setOpenMenu(true)}
            >
              <FormattedMessage id="go_menu" />
            </a>
          </li>
        </ul>
      </nav>

      <Header
        languageTo={languageTo}
        siteTitle={data.site.siteMetadata?.title || 'Title'}
        headerCssClass={headerCssClass}
        activeMenuLink={activeMenuLink}
        navLinks={navLinks}
        openMenu={openMenu}
      />
      <main
        id="maincontent"
        name="maincontent"
        className={`main ${mainCssClass ? mainCssClass : ''}`}
        role="main"
      >
        {children}
      </main>
      <Footer navLinks={navLinks} />
      <CookieList />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default injectIntl(Layout);
